<template lang="pug">
teleport(to="#app")
	transition(name="fade")
		.modal.flex.flex--fdc.flex--aic.flex--jcc(v-if="show" @click="$emit('update:show', false)")
			.modal__wrapper(@click.stop :class="[isDeleteModal ? 'modal-delete' : null]")
				.modal__close.pointer
					IconPlus(@click="$emit('update:show', false)" close)
				.modal__content
					.title.mb-4(v-if="title") {{ title }}
					slot
</template>

<script>
import IconPlus from './ui/icons/IconPlus'
import { watch } from 'vue'
export default {
	name: 'TheModal',
	components: { IconPlus },
	props: {
		show: {
			type: Boolean,
			required: true
		},
		title: {
			type: [String, null],
			required: null
		},
		isDeleteModal: {
			type: Boolean,
			default: false
		}
	},
	emits: ['update:show'],
	setup (props, { emit }) {
		const pressEscape = e => {
			if (e.key === 'Escape') {
				emit('update:show', false)
			}
		}
		watch(
			() => props.show,
			() => {
				if (props.show) {
					document.body.style.overflow = 'hidden'
					window.addEventListener('keydown', pressEscape)
				} else {
					document.body.style.overflow = null
					window.removeEventListener('keydown', pressEscape)
				}
			}
		)
	}
}
</script>

<style lang="scss" scoped>
.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba($mainColorHover, .75);
	z-index: 10;
	cursor: pointer;

	&__wrapper {
		cursor: initial;
		background-color: $bgPrimary;
		width: 100%;
		overflow: hidden;
	}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
		padding: 8px;
	}

	&__content {
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		padding: 40px 20px;
	}

	@include until-mobile {
		&__wrapper {
			height: 100%;
		}
	}

	@include for-tablet {
		&__wrapper {
			position: relative;
			border-radius: $radiusPrimary;
			width: 600px;
			min-height: 300px;
			max-height: 400px;

			&::before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 40px;
				background: linear-gradient(0, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
				pointer-events: none;
			}
		}
		&__wrapper.modal-delete{
			min-height: fit-content;
		}

		&__content {
			padding: 40px;
		}
	}
}
</style>
