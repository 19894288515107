import { reactive } from 'vue'
import { getInputDate } from '../../utils/getInputDate'

export const useInputsFromTo = () => {
	const date = new Date()

	const inputs = reactive({
		from: {
			placeholder: 'Начало периода',
			value: `${date.getFullYear()}-01-01`
		},
		to: {
			placeholder: 'Конец периода',
			value: getInputDate(date)
		}
	})

	return { inputs }
}
