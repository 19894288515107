<template lang="pug">
.grid
	.title События
	BaseGroup(title="Сформировать отчёт" :grid="3")
		BaseInput(
			v-for="(input, key) in inputs"
			:key="key"
			:placeholder="input.placeholder"
			type="date"
			v-model="input.value"
			required
			@blur="onChangeDate"
		)
		BaseButton(:disabled="formIsInvalid" @click="saveReport") Скачать
	BaseGroup(title="Фильтрация" :grid="4")
		component(
		v-for="(field, key) in filteringFields"
		v-model:option="filterData[field.option]"
		v-model="filterData[field.vModel]"
		:options="field.options"
		:is="field.component"
		:key="key"
		:placeholder="field.placeholder"
		:type="field?.type"
		@update:modelValue="field.component === 'BaseInput' ? handleUpdateFilters(field) : null"
		@update:option="field.component === 'BaseSelect' ? handleUpdateFilters(field) : null"
	)
	BaseGroup(title="Последние события")
		TheTable(:columns="tableColumns" :data="tableContent" action-buttons)
			template(v-slot:action-buttons="{ item }")
					.flex
						BaseButton(v-if="item?.place === 'factory'" @click="showEditModal(item.id)" icon='pen')
						BaseButton(v-if="item?.place === 'factory'" @click="showDeleteModal(item.id)" view="danger" icon='trash')
		BasePagination(
			v-model:value="pagination.page"
			:max="pagination.totalPages"
			@update:value="getData"
		)
	ModalDelete(v-model:show="modalDeleteState.isVisible" @delete="handleDeleteAction")
	ModalEditEventVue(
		v-model:show="modalEditState.isVisible"
		:data="modalEditState.data"
		:eventId="modalEditState.eventId"
		:key="modalEditState.eventId"
		@edit="handleUpdateAction")
</template>

<script>
import { nextTick, onMounted, reactive, ref, watch } from 'vue'

import { useApi } from '../modules/api'
import { useInputsFromTo } from '../modules/composition/useInputsFromTo'

import TheList from '../components/TheList'
import TheTable from '../components/TheTable'
import BasePagination from '../components/ui/BasePagination'
import BaseInput from '../components/ui/BaseInput'
import BaseButton from '../components/ui/BaseButton'
import BaseGroup from '../components/ui/BaseGroup'
import BaseSelect from '../components/ui/BaseSelect'
import { useAlert } from '@/modules/composition/useAlert'
import ModalDelete from '../components/modals/ModalDelete.vue'
import ModalEditEventVue from '../components/modals/ModalEditEvent.vue'
import FormattedDateTime from '@/utils/formattedDateTime'

export default {
	name: 'TheEvents',
	components: {
		BaseGroup,
		BaseButton,
		BaseInput,
		TheList,
		BasePagination,
		BaseSelect,
		ModalDelete,
		ModalEditEventVue,
		TheTable
	},
	setup () {
		const { errorAlert, infoAlert } = useAlert()
		const { inputs } = useInputsFromTo()
		const formIsInvalid = ref(false)

		const filterData = ref({
			fishName: null,
			sortName: null,
			weight: null,
			receptionPointName: null,
			workerFIO: null,
			from: null,
			to: null,
			place: null
		})
		const placeOption = { receiving_transport_ship: 'ПТС', factory: 'завод', reception_point: 'плашкоут' }
		const filteringFields = reactive({
			fishName: { placeholder: 'Вид рыбы', component: 'BaseSelect', options: [], option: 'fishName' },
			sortName: { placeholder: 'Сорт рыбы', component: 'BaseSelect', options: [], option: 'sortName' },
			weight: { placeholder: 'Вес рыбы', component: 'BaseInput', vModel: 'weight' },
			receptionPointName: { placeholder: 'Приёмка', component: 'BaseSelect', options: [], option: 'receptionPointName' },
			workerFIO: { placeholder: 'Принявший сотрудник', component: 'BaseSelect', options: [], option: 'workerFIO' },
			place: { placeholder: 'Место события', component: 'BaseSelect', options: placeOption, option: 'place' },
			from: { placeholder: 'От', component: 'BaseInput', type: 'datetime-local', vModel: 'from' },
			to: { placeholder: 'До', component: 'BaseInput', type: 'datetime-local', vModel: 'to' }
		})

		watch(inputs, async () => {
			await nextTick()
			formIsInvalid.value = document.querySelectorAll(':invalid').length > 0
		},
		{ deep: true }
		)

		const saveReport = async () => {
			const { post, data } = useApi('/actionsXml')

			const from = inputs.from.value
			const to = inputs.to.value

			const getTimestamp = (date) => +(new Date(date))

			await post({
				from: getTimestamp(from),
				to: getTimestamp(to)
			})

			const element = document.createElement('a')
			element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data.value))
			element.setAttribute('download', `Отчёт_приёмки_${from}_${to}.xml`)
			element.style.display = 'none'
			document.body.appendChild(element)
			element.click()
			document.body.removeChild(element)
		}

		const onChangeDate = () => {
			if (inputs.from.value > inputs.to.value) {
				inputs.to.value = null
			}
		}

		const pagination = reactive({
			page: 0,
			size: 20,
			totalPages: 0
		})

		onMounted(async () => {
			await getData()
			await getFishName()
			await getSortName()
			await getReceptionPointName()
			await getWorkerFio()
		})
		const actionsMap = ref({})
		const tableContent = ref([])
		const getData = async (isFilter = false, queryFilterString = '') => {
			const { get, data } = useApi('/getAllActionBox')
			if (isFilter && queryFilterString) {
				const defaultQueryString = '?offset=0&page=0&size=1000&'
				await get(defaultQueryString + queryFilterString)
			} else {
				await get(`?offset=0&page=${pagination.page}&size=${pagination.size}`)
			}
			const { content, totalPages } = data.value
			pagination.totalPages = totalPages

			tableContent.value = []

			content.forEach(line => {
				const { id, fishName, fishSort, receptionPointName, registerDate, rfid, status, weight, workerFIO, place, comment } = line
				actionsMap.value[id] = { fishName, fishSort, weight, registerDate }
				tableContent.value.push(
					{
						id: id,
						actionContent: `${receptionPointName || ''}: ${fishName || ''}, ${fishSort || ''} ${weight ? ', ' + weight + ' кг' : ''}`,
						status: `${status || ''} / ${workerFIO || ''} ${comment ? `(${comment})` : ''}`,
						date: `${FormattedDateTime(new Date(registerDate)) || ''}\r\n${rfid || ''}`,
						place: place
					}
				)
			})
		}
		const getFishName = async () => {
			const { get, data, error } = useApi('/dictionary/fish')
			await get()
			if (error.value) {
				errorAlert(`Ошибка при получении списка рыб! ${error.value?.message}`)
			} else {
				data.value.forEach(el => {
					filteringFields.fishName.options[el.id] = el.name
				})
			}
		}
		const getSortName = async () => {
			const { get, data, error } = useApi('/dictionary/fishSort')
			await get()
			if (error.value) {
				errorAlert(`Ошибка при получении списка видов рыб! ${error.value?.message}`)
			} else {
				data.value.forEach(el => {
					filteringFields.sortName.options[el.id] = el.name
				})
			}
		}
		const getReceptionPointName = async () => {
			const { get, data, error } = useApi('/listAllRP')
			await get()
			if (error.value) {
				errorAlert(`Ошибка при получении списка пунктов приёма! ${error.value?.message}`)
			} else {
				data.value.forEach(el => {
					filteringFields.receptionPointName.options.push(el)
				})
			}
		}
		const getWorkerFio = async () => {
			const { get, data, error } = useApi('/listAllWorkersFIO')
			await get()
			if (error.value) {
				errorAlert(`Ошибка при получении сотрудников! ${error.value?.message}`)
			} else {
				data.value.forEach(el => {
					filteringFields.workerFIO.options.push(el)
				})
			}
		}
		const getQueryString = () => {
			const prepareFilterData = {
				fishName: filteringFields.fishName.options[filterData.value.fishName],
				sortName: filteringFields.sortName.options[filterData.value.sortName],
				weight: filterData.value.weight,
				receptionPointName: filteringFields.receptionPointName.options[filterData.value.receptionPointName],
				workerFIO: filteringFields.workerFIO.options[filterData.value.workerFIO],
				to: filterData.value.to,
				from: filterData.value.from,
				place: filterData.value.place
			}

			let queryString = ''

			for (const key in prepareFilterData) {
				const value = prepareFilterData[key]
				if (value) {
					queryString += `${key}=${value}&`
				}
			}
			return queryString.slice(0, -1)
		}
		const timeout = ref(null)
		const handleUpdateFilters = async () => {
			clearTimeout(timeout.value)
			const filterString = getQueryString()
			// debounce
			timeout.value = setTimeout(() => {
				return getData(true, filterString)
			}, 300)
		}
		const modalDeleteState = ref({
			isVisible: false,
			id: null
		})
		const modalEditState = reactive({
			isVisible: false,
			data: null,
			eventId: null
		})
		const showDeleteModal = (id) => {
			if (id) {
				modalDeleteState.value.isVisible = true
				modalDeleteState.value.id = id
			}
		}
		const handleDeleteAction = async () => {
			const { del, error } = useApi(`/deleteActionBox/?id=${modalDeleteState.value.id}`)

			await del()

			if (error.value) {
				errorAlert(`Ошибка в удалении события! ${error.value?.message}`)
			} else {
				const filterString = getQueryString()
				await getData(true, filterString)
				infoAlert('Событие успешно удалено')
				modalDeleteState.value.isVisible = false
			}
		}
		const handleUpdateAction = async () => {
			const filterString = getQueryString()
			await getData(true, filterString)
		}
		const showEditModal = (id) => {
			if (id) {
				modalEditState.isVisible = true
				modalEditState.eventId = id
				modalEditState.data = [
					{
						value: actionsMap.value[id].fishName,
						options: { ...filteringFields.fishName.options },
						placeholder: 'Вид рыбы'
					},
					{
						value: actionsMap.value[id].fishSort,
						options: { ...filteringFields.sortName.options 	},
						placeholder: 'Сорт рыбы'
					},
					{
						value: actionsMap.value[id].weight,
						placeholder: 'Вес рыбы'
					},
					{
						value: ((currentDate) => {
							const year = currentDate.getFullYear()
							const month = String(currentDate.getMonth() + 1).padStart(2, '0')
							const day = String(currentDate.getDate()).padStart(2, '0')
							const hours = String(currentDate.getHours()).padStart(2, '0')
							const minutes = String(currentDate.getMinutes()).padStart(2, '0')
							return `${year}-${month}-${day} ${hours}:${minutes}`
						})(new Date(actionsMap.value[id].registerDate)),
						type: 'date',
						placeholder: 'Дата'
					}
				]
			}
		}
		const tableColumns = ref([
			{ title: 'Событие', value: 'actionContent' },
			{ title: 'Статус', value: 'status' },
			{ title: 'Дата', value: 'date' }
		])
		return {
			inputs,
			formIsInvalid,
			saveReport,
			onChangeDate,

			pagination,
			getData,

			filteringFields,
			getFishName,
			filterData,
			handleUpdateFilters,

			showDeleteModal,
			showEditModal,
			modalDeleteState,
			modalEditState,

			handleDeleteAction,
			handleUpdateAction,
			tableContent,
			tableColumns
		}
	}
}
</script>
