<template lang="pug">
.pagination(v-if="max > 1")
	.pagination__point.pagination__point--start(
		:class="{'pagination__point--passive': value === 0}"
		v-on:click="decrease"
	) {{'<'}}
	.pagination__point(
		v-for="i in points"
		:class="{'pagination__point--active': i === value}"
		v-on:click="setPage(i + 1)"
	) {{ i + 1 }}
	.pagination__point.pagination__point--finish(
		:class="{'pagination__point--passive': value === max - 1}"
		v-on:click="increase"
	) {{'>'}}
</template>

<script>

import { computed } from 'vue'

export default {
	name: 'BasePagination',
	props: {
		max: {
			type: Number,
			default: 0
		},
		value: {
			type: Number,
			default: 0
		}
	},
	emits: ['update:value'],
	setup (props, { emit }) {
		const points = computed(() => {
			const LENGTH = 5
			const HALF_LENGTH = Math.floor(LENGTH / 2)

			if (props.max < LENGTH) {
				return Array.from(Array(props.max).keys())
			}
			if (props.value <= HALF_LENGTH) {
				return Array.from(Array(LENGTH).keys())
			}
			if (props.max - props.value <= HALF_LENGTH) {
				return Array.from({ length: LENGTH }, (_, i) => props.max - LENGTH + i)
			}
			return Array.from({ length: LENGTH }, (_, i) => i + props.value - HALF_LENGTH)
		})
		const setPage = (i) => {
			emit('update:value', i - 1)
		}
		const decrease = () => {
			if (props.value > 0) {
				emit('update:value', props.value - 1)
			}
		}
		const increase = () => {
			if (props.value < props.max) {
				emit('update:value', props.value + 1)
			}
		}

		return {
			points,
			setPage,
			decrease,
			increase
		}
	}
}
</script>

<style scoped lang="scss">
.pagination {
	user-select: none;
	text-align: center;

	*:not(.pagination__point--dots) {
		cursor: pointer;
	}

	&__point {
		display: inline-block;
		vertical-align: top;
		min-width: 40px;
		height: 40px;
		font-size: 14px;
		line-height: 40px;
		text-align: center;
		color: $mainColor;
		border: 1px solid $mainColor;
		margin: 6px -1px 6px 0;
		padding: 0 12px;
		transition: 0.3s ease;
		transition-property: background-color, color;

		&--start {
			border-radius: 4px 0 0 4px;
		}

		&--finish {
			border-radius: 0 4px 4px 0;
		}

		&--active {
			background-color: $mainColor;
			color: $colorInverse;
			cursor: default;
			pointer-events: none;
		}

		&--passive {
			pointer-events: none;
			opacity: 0.5;
		}

		&:hover {
			&:not(.pagination__point--active) {
				background-color: rgba($bgMain, 0.2);
			}
		}
	}
}
</style>
