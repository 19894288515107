<template lang="pug">
.list-checkbox-select-all(v-if="checkboxsList")
	<input v-model="selectAllValue" type="checkbox" id="select-all-list-checkbox" class="list-checkbox" @change="selectAll">
	<label for="select-all-list-checkbox" class="list-checkbox-select-all__label" @change="selectAll">Выбрать всё</label>
.list
	.list__element.pointer(
		v-if="!checkboxsList"
		v-for="(element, key) in map"
		:key="map"
		:class="{'list__checkbox': checkboxsList, hover}"
		@click="onClick(element, key)"
	)
		p
			b(v-if="element.left" v-html="markedSubstring(element.left)")
			span(v-if="element.leftSub" v-html="markedSubstring(element.leftSub)")
		p
			b(v-if="element.right" v-html="markedSubstring(element.right)")
			span(v-if="element.rightSub" v-html="markedSubstring(element.rightSub)")
	template(v-for="(element, key) in map" v-if="checkboxsList")
		.list__element.pointer(
			v-if="element.viseble"
			:key="map"
			:class="{'list__checkbox': checkboxsList, hover}"
			@click="onClick(element, key)"
		)
			<input v-if="checkboxsList" v-model="element.checked" type="checkbox" class="list-checkbox" :key="key">
				p
					b(v-if="element.left" v-html="markedSubstring(element.left)")
					span(v-if="element.leftSub" v-html="markedSubstring(element.leftSub)")
				p
					b(v-if="element.right" v-html="markedSubstring(element.right)")
					span(v-if="element.rightSub" v-html="markedSubstring(element.rightSub)")
	.list-button(v-if="checkboxsList")
		BaseButton(@click="onConfirm") Потвердить
</template>

<script>
import BaseButton from './ui/BaseButton.vue'
import { ref, watch, onMounted } from 'vue'
export default {
	name: 'TheList',
	components: { BaseButton },
	props: {
		map: {
			type: Object,
			default: () => { }
		},
		search: {
			type: [String, null],
			default: null
		},
		hover: {
			type: Boolean,
			default: true
		},
		actionButtons: {
			type: Boolean,
			default: false
		},
		checkboxsList: {
			type: Boolean,
			default: false
		}
	},
	emits: ['onClick', 'onDelete', 'onConfirm'],
	setup (props, { emit }) {
		const markedSubstring = (string) => {
			if (props.search) {
				const start = string.indexOf(props.search)

				if (start >= 0) {
					const finish = start + props.search.length

					return `${string.slice(0, start)}<span class="marked-text">${string.slice(start, finish)}</span>${string.slice(finish)}`
				}
			}
			return string
		}

		const selectAllValue = ref(false)
		const selectAll = () => {
			const list = props.map
			for (const key in list) {
				if (list[key].viseble) {
					list[key].checked = true
				}
			}
			if (!selectAllValue.value) {
				const arr = []
				for (const key in list) {
					arr.push(list[key].checked)
				}
				if (arr.every(el => el === true)) {
					for (const key in list) {
						if (list[key].viseble) {
							list[key].checked = false
						}
					}
				}
			}
		}
		watch(() => props.map, (newMap) => {
			const allChecked = Object.values(newMap).every(element => element.checked === true)
			selectAllValue.value = allChecked
		},
		{ deep: true })
		const onConfirm = () => {
			const selectedItems = []
			const deselectItems = []
			for (const key in props.map) {
				if (props.map[key].checked) {
					selectedItems.push(key)
				} else {
					deselectItems.push(key)
				}
			}
			emit('onConfirm', selectedItems, deselectItems)
		}
		const onClick = (element, key) => {
			if (props.checkboxsList) {
				element.checked = !element.checked
			} else {
				emit('onClick', key)
			}
		}
		onMounted(() => {
			if (props.checkboxsList) {
				const allChecked = Object.values(props.map).every(element => element.checked === true)
				selectAllValue.value = allChecked
			}
		})
		return {
			markedSubstring,
			selectAll,
			onClick,
			selectAllValue,
			onConfirm
		}
	}
}
</script>

<style lang="scss" scoped>
.list {
	&-button {
		display: flex;
		padding: 5px;
		justify-content: flex-end;
	}

	&-checkbox-select-all {
		display: flex;
		justify-content: flex-start;
		padding: 4px;
		gap: 5px;
		align-items: center;

		&__label {
			cursor: pointer;
		}

	}

	&-checkbox {
		width: 20px;
		height: 20px;
	}

	&__element {
		transition: background-color 0.3s ease;
		padding: 4px;
		display: grid;
		&.list__checkbox {
			display: flex;
			justify-content: flex-start;
			gap: 15px;
		}
		p {
			b {
				display: block;
				margin-bottom: 2px;
			}
		}

		@include until-mobile {
			p+p {
				margin-top: 4px;
			}
		}

		@include for-tablet {
			display: flex;
			justify-content: space-between;
			align-items: center;

			p {
				&:last-child {
					text-align: right;
				}
			}

			&.hover {
				&:hover {
					background-color: rgba($bgMain, 0.1);
				}
			}
		}

		&+& {
			border-top: 1px solid $borderPrimary;
		}
	}
}

.btn-container {
	min-width: 15px;
	min-height: 20px;

	@include until-mobile {
		display: flex;
		flex-direction: column;
	}
}
</style>
