<template lang="pug">
TheModal(
	:show="show"
	@update:show="onClose"
	title="Изменение данных о событие"
	)
    BaseGroup.mb-4(title="Основная информация")
        template(
			v-for="(field, key) in data"
			:key="key"
			)
            BaseSelect(
                v-if="field.options"
                :options="field.options"
                :placeholder="field.placeholder"
                v-model:option="field.value"
                :default="field.value"
            )
            BaseInput(
                v-else-if="field.type === 'date'"
                :placeholder="field.placeholder"
                v-model="field.value"
				:disabled="isDateMoreThanTreeDays"
                type="datetime-local"
			)
            BaseInput(
                v-else
                :placeholder="field.placeholder"
                v-model="field.value"
            )
        BaseButton(@click="editEvent") Редактировать
</template>

<script>
import TheModal from '../TheModal'

import BaseGroup from '../ui/BaseGroup'
import BaseInput from '../ui/BaseInput'
import BaseSelect from '../ui/BaseSelect'
import BaseButton from '../ui/BaseButton'

import { useApi } from '../../modules/api'
import { useAlert } from '../../modules/composition/useAlert'

export default {
	name: 'ModalBoat',
	components: { BaseButton, BaseSelect, BaseInput, BaseGroup, TheModal },
	props: {
		show: {
			type: [String, Boolean],
			required: true
		},
		data: {
			type: Object,
			required: true
		},
		eventId: {
			type: String,
			required: true
		}
	},
	emits: ['update:show', 'edit'],
	setup (props, { emit }) {
		const { errorAlert, infoAlert } = useAlert()
		const editEvent = async () => {
			const { post, data, error } = useApi('/editActionBox')
			await post({
				id: props.eventId,
				fishName: props.data[0].options[props.data[0].value],
				fishSort: props.data[1].options[props.data[1].value],
				weight: props.data[2].value,
				date: ((currentDate) => {
					const year = currentDate.getFullYear()
					const month = String(currentDate.getMonth() + 1).padStart(2, '0')
					const day = String(currentDate.getDate()).padStart(2, '0')
					const hours = String(currentDate.getHours()).padStart(2, '0')
					const minutes = String(currentDate.getMinutes()).padStart(2, '0')
					return `${year}-${month}-${day}T${hours}:${minutes}:00`
				})(new Date(props.data[3].value))
			})
			if (error.value) {
				errorAlert(`Не удалось изменить событие! ${error?.value || ''}`)
			} else {
				emit('edit', data)
				infoAlert('Событие успешно изменено')
				onClose()
			}
		}

		const onClose = () => {
			emit('update:show', false)
		}

		return {
			editEvent,
			onClose
		}
	},
	computed: {
		isDateMoreThanTreeDays () {
			if (this?.data[3]?.value && this.data[3].value !== null) {
				const currentDate = new Date()
				const selectedDateObj = new Date(this.data[3].value)
				const diffInMs = currentDate - selectedDateObj
				const threeDaysInMs = 3 * 24 * 60 * 60 * 1000

				return diffInMs > threeDaysInMs
			}
			return false
		}
	}
}
</script>
