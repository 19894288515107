<template lang="pug">
.table-title(v-if="title") {{ title }}
.table-wrapper
  .the-table
    table(:class="tableClass")
      thead
        tr
          th(v-for="column in columns" :key="column.id") {{ column.title }}
          th.column-btn.column-btn-th(v-if="actionButtons" action-buttons)
      tbody
        tr(v-for="item in data" :key="item.id")
          td(v-for="column in columns" :key="column.id")
            p(:class="cellClass") {{ item[column.value] }}
          td.column-btn.column-btn-tr(v-if="actionButtons")
            .flex.btn-container
              slot(name="action-buttons"  v-bind="{ item }")
</template>

<script>
// import { ref } from 'vue'

export default {
	name: 'TheTable',
	props: {
		title: {
			type: String,
			default: ''
		},
		columns: {
			type: Array,
			default: () => []
		},
		data: {
			type: Array,
			default: () => []
		},
		actionButtons: {
			type: Boolean,
			default: false
		},
		tableClass: {
			type: String,
			default: null
		},
		cellClass: {
			type: String,
			default: null
		},
		vHtml: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style lang="scss" scoped>
.table-wrapper {
  border: 1px solid #0862c2;
  border-radius: 10px;
  overflow: hidden;
}

.table-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
}

.column-btn {
  max-width: fit-content;
}

.the-table {
  width: 100%;
  border-radius: 10px;

  table {
    width: 100%;
    border-collapse: collapse;
    border: none;

    thead {
      font-weight: bold;
    }

    th,
    td {
      padding: 5px;
      border: 1px solid #0862c2;
    }
    td p {
      white-space: pre-wrap;
    }
    td.column-btn {
      width: 1%;
      white-space: nowrap;
    }

    button {
      margin-right: 5px;
    }

    tr:first-child td {
      border-top: none;
    }

    tr:last-child td {
      border-bottom: none;
    }

    tr td:first-child {
      border-left: none;
    }

    tr td:last-child {
      border-right: none;
    }

    tr:first-child th {
      border-top: none;
    }

    tr th:first-child {
      border-left: none;
    }

    tr th:last-child {
      border-right: none;
    }
  }

  tr:hover td {
    background-color: #f5f3f3;
  }

  /* Горизонтальная прокрутка */
  overflow-x: auto;
  position: relative;

  /* Стилизация полосы прокрутки при прокрутке */
  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #dcdcdc;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bgMain;
    border-radius: 3px;
  }
}</style>
