<template lang="pug">
TheModal(:show="show" @update:show="onClose" title="Вы действительно хотите удалить?" :isDeleteModal="true")
    .delete-button-container
        BaseButton(@click="onDelete" view="danger") Удалить
        BaseButton(@click="$emit('update:show', false)" ) Отмена
</template>

<script>
import TheModal from '../TheModal'
import BaseButton from '../ui/BaseButton'

export default {
	name: 'ModalBoat',
	components: { BaseButton, TheModal },
	props: {
		show: {
			type: [String, Boolean],
			required: true
		}
	},
	emits: ['update:show', 'delete'],
	setup (props, { emit }) {
		const onDelete = () => {
			emit('delete', true)
		}
		const onClose = () => {
			emit('update:show', false)
		}
		return {
			onDelete,
			onClose
		}
	}
}
</script>

<style lang="scss" scoped>
.delete-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 15px;
}
</style>
